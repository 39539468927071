import checkIcon from "../assets/check-icon-bordo.svg";
import fileIcon from "../assets/file_icon.svg";
import removeIcon from "../assets/remove-icon.svg";
import attachIcon from "../assets/attach.svg";
import * as React from "react";
import '../styles/Home.css';
import '../styles/Spinner.css';
import {useState} from "react";
import { usePlacesWidget } from 'react-google-autocomplete';
import {getFullAddress, getShortAddress} from "../utils/utils";
import {PassportPageKeys} from "./RegistrationPage";
import infoIcon from '../assets/info_icon.svg';

const {REACT_APP_GOOGLE_MAPS_KEY} = process.env;
const GoogleAutocompleteOptions = {
    apiKey: REACT_APP_GOOGLE_MAPS_KEY,
    options: {
        types: ['address']
    },
    language: 'ru'
};

export function RegistrationForm(props: {
    isLoading: boolean;
    isAgreement: boolean;
    onSubmit: (e: any) => any;
    setCardId: (cardId: number) => any;
    setIsAgreement: () => any;
    setFullRegistrationAddress: (address: string) => any;
    setFullActualAddress: (address: string) => any;
    onRemoveFile: (key: PassportPageKeys) => any;
    uploadFiles: (files: FileList | null, key: PassportPageKeys) => any;
    cards: any[];
    cardId: number;
    frontPageRuPassportFile?: File;
    registrationPageRuPassportFile?: File;
    frontPageForeignPassportFile?: File;
    fullRegistrationAddress: string;
    fullActualAddress: string;
}) {
    const {isLoading,
        cards, cardId, frontPageRuPassportFile, registrationPageRuPassportFile, frontPageForeignPassportFile, isAgreement, fullRegistrationAddress, fullActualAddress,
        setCardId, setIsAgreement, setFullRegistrationAddress, setFullActualAddress, onRemoveFile, uploadFiles, onSubmit} = props;
    const [isEqualAddresses, setIsEqualAddresses] = useState(false);
    const [familyStatusClassName, setFamilyStatusClassName] = useState('gray');
    const [openedLabel, setOpenedLabel] = useState('');

    const handleIsEqualAddresses = () => {
        if (!isEqualAddresses) {
            const value = (document.getElementById('registration_address') as any).value;
            (document.getElementById('actual_address') as any).value = value;
            sessionStorage.setItem('actual_address', value);
            setFullActualAddress(sessionStorage.getItem('full_registration_address') || value);
            sessionStorage.setItem('full_actual_address', sessionStorage.getItem('full_registration_address') || value);
        }
        setIsEqualAddresses(!isEqualAddresses);
    }

    const handlePlaceSelected = (place: any, fieldId: string) => {
        if (!place.formatted_address) {
            return;
        }
        const shortAddress = getShortAddress(place);
        const fullAddress = getFullAddress(place);
        fieldId === 'registration_address'
            ? setFullRegistrationAddress(fullAddress)
            : setFullActualAddress(fullAddress);
        (document.getElementById(fieldId) as any).value = shortAddress + ' ';
        sessionStorage.setItem(fieldId, shortAddress);
        sessionStorage.setItem(`full_${fieldId}`, fullAddress);
        if ((document.getElementById('is_equal_addresses') as any).checked) {
            const invertFieldId = fieldId === 'registration_address'
                ? 'actual_address'
                : 'registration_address';
            (document.getElementById(invertFieldId) as any).value = shortAddress;
            sessionStorage.setItem(invertFieldId, shortAddress);
            sessionStorage.setItem(`full_${invertFieldId}`, fullAddress);
            if (fieldId === 'registration_address') {
                setFullActualAddress(fullAddress);
            } else {
                setFullRegistrationAddress(fullAddress);
            }
        }
        const optionsContainer = document.getElementsByClassName('pac-container').item(fieldId === 'registration_address' ? 0 : 1);
        if (optionsContainer) {
            optionsContainer.setAttribute('style', 'display: none;');
        }
    }

    const refReg = usePlacesWidget({
        ...GoogleAutocompleteOptions,
        onPlaceSelected: (place) => handlePlaceSelected(place, 'registration_address')
    }).ref;

    const refAct = usePlacesWidget({
        ...GoogleAutocompleteOptions,
        onPlaceSelected: (place) => handlePlaceSelected(place, 'actual_address')
    }).ref;

    return <div className='foreign-card-container' style={{padding: '16px 16px 180px 16px'}}>
        {isLoading && <div id='spinner'/>}
        <form
            id='registration-form'
            onSubmit={onSubmit}
            onInput={(e) => {
                try {
                    const name = (e.target as any).name;
                    const value = (e.target as any).value;
                    sessionStorage.setItem(name, value);
                    if (name === 'family_status') {
                        setFamilyStatusClassName(value === '' ? 'gray' : '');
                    } else if (name === 'registration_address') {
                        setFullRegistrationAddress(value);
                        sessionStorage.setItem(`full_${name}`, value);
                    } else if (name === 'actual_address') {
                        setFullActualAddress(value);
                        sessionStorage.setItem(`full_${name}`, value);
                    }
                } catch (e) {
                    console.log('error: ' + e);
                }
            }}
        >
            <div className='foreign-card__label'>
                Данные из российского паспорта:
            </div>
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Фамилия кириллицей')}
                id='last_name'
                name='last_name'
                placeholder='Фамилия*'
                required={true}
                minLength={2}
                pattern={'^[А-Яа-яЁё\\-\\s]+$'}
                type='text'
                title='Фамилия кириллицей'
                autoCapitalize='words'
            />
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Имя кириллицей')}
                id='first_name'
                name='first_name'
                placeholder='Имя*'
                required={true}
                minLength={2}
                pattern={'^[А-Яа-яЁё\\-\\s]+$'}
                type='text'
                title='Имя кириллицей'
                autoCapitalize='words'
            />
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Отчество кириллицей')}
                id='middle_name'
                name='middle_name'
                placeholder='Отчество*'
                required={true}
                minLength={2}
                pattern={'^[А-Яа-яЁё\\-\\s]+$'}
                type='text'
                title='Отчество кириллицей'
                autoCapitalize='words'
            />
            <div
                id='datepicker_error'
                className='label_error'
            >
                Введите корректную дату рождения
            </div>
            <div className='grid'>
                <input
                    type='date'
                    inputMode='numeric'
                    required={true}
                    placeholder='Дата рождения*'
                    name='birthdate'
                    id='birthdate'
                />
                <input
                    type='text'
                    placeholder='Дата рождения*'
                    id='pseudo_birthdate'
                />
                <select
                    name='family_status'
                    id='family_status'
                    required={true}
                    title='Семейное положение*'
                    className={familyStatusClassName}
                    defaultValue=''
                >
                    <option value='' disabled>
                        Семейное положение*
                    </option>
                    {['состоит в браке', 'разведен(а)', 'не состоит в браке'].map((option, i) =>
                        <option key={i}>{option}</option>)}
                </select>
                <div className='address_container'>
                    {fullRegistrationAddress !== sessionStorage.getItem('registration_address') &&
                        <label htmlFor='registration_address'>
                            {fullRegistrationAddress}
                        </label>}
                    <input
                        ref={refReg as any}
                        name='registration_address'
                        id='registration_address'
                        placeholder='Адрес регистрации*'
                        className={fullRegistrationAddress === sessionStorage.getItem('registration_address') || !sessionStorage.getItem('registration_address')
                            ? 'centered'
                            : ''}
                        required={true}
                        minLength={2}
                    />
                </div>
                <label style={{margin: '6px 0 0 0'}}>
                    Конверт с картой вручается исключительно в руки владельцу карты
                </label>
                <div className='address_container'>
                    {fullActualAddress !== sessionStorage.getItem('actual_address') && <label htmlFor='actual_address'>
                        {fullActualAddress}
                    </label>}
                    <input
                        ref={refAct as any}
                        name='actual_address'
                        id='actual_address'
                        placeholder='Желаемый адрес доставки карты*'
                        className={fullActualAddress === sessionStorage.getItem('actual_address') || !sessionStorage.getItem('actual_address')
                            ? 'centered'
                            : ''}
                        required={true}
                        minLength={2}
                    />
                </div>
                <div
                    className='checkbox_container flex'
                    onClick={handleIsEqualAddresses}
                >
                    <input
                        type='checkbox'
                        checked={isEqualAddresses}
                        onChange={handleIsEqualAddresses}
                        id='is_equal_addresses'
                    />
                    <label>
                        Совпадает с адресом регистрации
                    </label>
                </div>
            </div>
            <div className='foreign-card__label'>
                Данные из заграничного паспорта:
            </div>
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Имя латиницей')}
                id='int_passport_first_name'
                name='int_passport_first_name'
                placeholder='Имя*'
                required={true}
                minLength={2}
                pattern={'^[a-zA-Z\\-\\s]+$'}
                type='text'
                title='Имя латиницей'
                autoCapitalize='characters'
            />
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Фамилия латиницей')}
                id='int_passport_last_name'
                name='int_passport_last_name'
                placeholder='Фамилия*'
                required={true}
                minLength={2}
                pattern={'^[a-zA-Z\\-\\s]+$'}
                type='text'
                title='Фамилия латиницей'
                autoCapitalize='characters'
            />
            <div className='foreign-card__label'>
                Контактные данные:
            </div>
            <div className='tel_input_container' id='tel_input_container'>
                <div>
                    +7
                </div>
                <input
                    type='tel'
                    placeholder='Номер телефона*'
                    maxLength={13}
                    name='phone'
                    id='phone'
                    required={true}
                />
            </div>
            <input
                name='email'
                id='email'
                placeholder='Электронная почта*'
                required={true}
                minLength={2}
            />
            <input
                name='place_of_work'
                id='place_of_work'
                placeholder='Место работы, если ИП – описать деятельность*'
                required={true}
                minLength={2}
            />
            <input
                name='job_title'
                id='job_title'
                placeholder='Должность*'
                required={true}
                minLength={2}
            />
            <input
                onInvalid={e => (e.target as any).setCustomValidity('Должно содержать только 1 слово из букв и/или дополнительно цифр')}
                name='codeword'
                id='codeword'
                pattern={'^(?=.*[A-Za-z])[A-Za-z0-9]+$'}
                placeholder='Кодовое слово*'
                required={true}
                minLength={2}
                title={'Не должно содержать ФИО'}
            />
            <label htmlFor={'codeword'}>
                Не должно содержать ФИО
            </label>
            <div className='foreign-card__label'>
                Тип карты
            </div>
            {cards && <div className={`flex ${cards.length > 2 ? 'wrap' : ''}`}>
                {cards.map((type: any, i: number) => {
                    const isSelectedType = cardId === type.id;
                    return <div
                        key={i}
                        className={`foreign-card__checkbox_container__checkbox card ${isSelectedType ? 'selected' : ''}`}
                        onClick={() => {
                            if (!isSelectedType) {
                                setCardId(type.id);
                                sessionStorage.setItem('cardId', type.id);
                            }
                        }}
                    >
                        {type.name}
                        {isSelectedType && <img src={checkIcon} alt='check icon'/>}
                    </div>;
                })}
            </div>}
            <div className='file_container'>
                {frontPageRuPassportFile && <div
                    className='file-uploader flex'
                >
                    <img
                        src={fileIcon}
                        alt='attach icon'
                        className='file_icon'
                    />
                    <div className='file_name'>
                        {frontPageRuPassportFile.name}
                    </div>
                    <img
                        src={removeIcon}
                        alt='attach icon'
                        className='file_remove_icon'
                        onClick={() => onRemoveFile('frontRu')}
                    />
                </div>}
                <div className='file-uploader flex'>
                    <img src={attachIcon} alt='attach icon' className='file_attach_icon'/>
                    <input
                        autoComplete='off'
                        type='file'
                        multiple={false}
                        accept='application/pdf'
                        onChange={(e) => uploadFiles(e.target.files, 'frontRu')}
                    />
                    <div className='file-uploader_label' id='file-uploader_label__front_page'>
                        <div>
                            Прикрепить скан первой страницы паспорта РФ (разворот с фото)
                            <img
                                src={infoIcon}
                                alt='info icon'
                                onClick={() => setOpenedLabel(prevState => prevState === 'frontRu' ? '' : 'frontRu')}
                            />
                        </div>
                        {openedLabel === 'frontRu' && <div className='file-uploader_label__info'>
                            Строго pdf формат, скан полного документа, не допуская обрезания границ, скан «со стекла»,
                            без посторонних
                            предметов в изображении и бликов/засветов». Фотографии под видом сканов будут отклонены!
                        </div>}
                    </div>
                </div>
            </div>
            <div className='file_container'>
                {registrationPageRuPassportFile && <div
                    className='file-uploader flex'
                >
                    <img
                        src={fileIcon}
                        alt='attach icon'
                        className='file_icon'
                    />
                    <div className='file_name'>
                        {registrationPageRuPassportFile.name}
                    </div>
                    <img
                        src={removeIcon}
                        alt='attach icon'
                        className='file_remove_icon'
                        onClick={() => onRemoveFile('regRu')}
                    />
                </div>}
                <div className='file-uploader flex'>
                    <img src={attachIcon} alt='attach icon' className='file_attach_icon'/>
                    <input
                        autoComplete='off'
                        type='file'
                        multiple={false}
                        accept='application/pdf'
                        onChange={(e) => uploadFiles(e.target.files, 'regRu')}
                    />
                    <div className='file-uploader_label' id='file-uploader_label__reg_page'>
                        <div>
                            Прикрепить скан страницы регистрации (прописки) паспорта РФ
                            <img
                                src={infoIcon}
                                alt='info icon'
                                onClick={() => setOpenedLabel(prevState => prevState === 'regRu' ? '' : 'regRu')}
                            />
                        </div>
                        {openedLabel === 'regRu' && <div className='file-uploader_label__info'>
                            Строго pdf формат, скан полного документа, не допуская обрезания границ, скан «со стекла»,
                            без посторонних
                            предметов в изображении и бликов/засветов». Фотографии под видом сканов будут отклонены!
                        </div>}
                    </div>
                </div>
            </div>
            <div className='file_container'>
                {frontPageForeignPassportFile && <div
                    className='file-uploader flex'
                >
                    <img
                        src={fileIcon}
                        alt='attach icon'
                        className='file_icon'
                    />
                    <div className='file_name'>
                        {frontPageForeignPassportFile.name}
                    </div>
                    <img
                        src={removeIcon}
                        alt='attach icon'
                        className='file_remove_icon'
                        onClick={() => onRemoveFile('frontForeign')}
                    />
                </div>}
                <div className='file-uploader flex'>
                    <img src={attachIcon} alt='attach icon' className='file_attach_icon'/>
                    <input
                        autoComplete='off'
                        type='file'
                        multiple={false}
                        accept='application/pdf'
                        onChange={(e) => uploadFiles(e.target.files, 'frontForeign')}
                        id='file-uploader-input__front_page_foreign'
                    />
                    <div className='file-uploader_label' id='file-uploader_label__front_page_foreign'>
                        <div>
                            Прикрепить скан первой страницы загранпаспорта (разворот с фото)
                            <img
                                src={infoIcon}
                                alt='info icon'
                                onClick={() => setOpenedLabel(prevState => prevState === 'frontForeign' ? '' : 'frontForeign')}
                            />
                        </div>
                        {openedLabel === 'frontForeign' && <div className='file-uploader_label__info'>
                            Строго pdf формат, скан полного документа, не допуская обрезания границ, скан «со стекла»,
                            без посторонних
                            предметов в изображении и бликов/засветов». Фотографии под видом сканов будут отклонены!
                        </div>}
                    </div>
                </div>
            </div>
            <label className='label_manager_info'>
                Только для сотрудников банка
            </label>
            <input
                name='manager_info'
                id='manager_info'
                placeholder='ПромоКод / ID менеджера'
                minLength={2}
            />
            <div className='submit_button__container'>
                <div
                    className='submit_button__container__agreement'
                    onClick={setIsAgreement}
                >
                    <input
                        type={'checkbox'}
                        checked={isAgreement}
                        onChange={() => ''}
                    />
                    <div>
                        Нажимая на кнопку 'Отправить заявку', я даю{' '}
                        <a href='/oferta' target='_blank' rel='noreferrer'>
                            Согласие на обработку персональных данных, принимаю условия Публичного договора-оферты на
                            оказание информационно-консультативных услуг
                        </a>
                    </div>
                </div>
                <button
                    type='submit'
                    id='submit_button'
                    className='disabled'
                    disabled={isLoading || !isAgreement}
                >
                    Отправить заявку
                </button>
            </div>
        </form>
    </div>;
}
